<script>
import CatalogElement from '../components/CatalogElement';
import { mapState, mapGetters } from 'vuex';

export default
{
    components:
    {
        'catalog-element': CatalogElement
    },
    data()
    {
        return {
            searchFull: '',
            searchBox: false,
            viewMode: 'grid',
            introDialog: false,
            loginDialog: false,
            login: '',
            password: '',
            loginError: '',
            loginProgress: false,
            loginRoute: ''
        };
    },
    watch:
    {
        searchFull: function(value)
        {
            const scope = this;
            scope.$store.commit('setFullSearch', value);
        }
    },
    computed:
    {
        ...mapState(
        {
            books: state => state.books
        }),
        ...mapGetters(
        {
            userToken: 'getUserToken'
        })
    },
    mounted()
    {
        const scope = this;
        if (localStorage.getItem('ELIB_DLG_INTRO_CONFIRM') !== '1')   scope.introDialog = true;
    },
    methods:
    {
        toggleSearch()
        {
            const scope = this;
            if (scope.searchBox)
            { // hide searching
                scope.searchFull = '';
                scope.searchBox = false;
            }
            else
            { // show searching
                scope.searchBox = true;
            }
        },
        toggleViewMode()
        {
            const scope = this;
            if      (scope.viewMode=='rows') scope.viewMode = 'grid';
            else if (scope.viewMode=='grid') scope.viewMode = 'rows';
            scope.$store.commit('setViewMode', scope.viewMode);
        },
        confirmIntro()
        {
            const scope = this;
            localStorage.setItem('ELIB_DLG_INTRO_CONFIRM', '1');
            scope.introDialog = false;
        },
        loginUser()
        {
            const scope = this;
            scope.loginProgress = true;
            scope.loginError = '';
            const cmd = {};
            cmd.login = scope.login;
            cmd.password = scope.password;
            const headers = {};
            headers["Content-Type"] = "application/json";
            scope.$http({method: 'POST', url: 'https://api.pulib.sk/api/v1/auth/login', headers: headers, body: cmd})
            .then(function(response)
            { // success callback
                let result = response.body || {};
                scope.$store.commit('setUserToken', result.token);
                scope.loginDialog = false;
                if (scope.loginRoute)   scope.onDocumentOpen(scope.loginRoute);
                scope.loginRoute = '';
            },
            function(error)
            { // error callback
                let status = error.status;
                scope.loginError = 'Overenie totožnosti zlyhalo, prosím skúste znova...';
            })
            .finally(function()
            {
                scope.loginProgress = false;
            });

        },
        logoutUser()
        {
            const scope = this;
            scope.$store.commit('setUserToken', '');
            window.location.reload();
        },
        onDocumentOpen(code)
        {
            const scope = this;
            const doc = scope.books.find(item => item.code==code);
            if (doc)
            {
                if (doc.access=='library' && !scope.userToken)
                {
                    scope.loginRoute = code;
                    scope.login = '';
                    scope.password = '';
                    scope.loginDialog = true;
                }
                else
                {
                    if (doc.isbn)   scope.$router.push({name: 'document', params: { codetype: 'isbn', code: doc.isbn}});
                    else            scope.$router.push({name: 'document', params: { codetype: 'ean', code: doc.code}});
                }
            }
        },
        onAccountMenu(code)
        {
            const scope = this;
        }
    }

};
</script>


<template>

    <v-app>
        <v-toolbar app promiment flat :scroll-off-screen="!searchBox" :scroll-threshold="100" class="elevation-10" style="background: #f4f4f4;">

            <v-layout row>
                <v-flex sm10 offset-sm1 xs12>
                    <v-card flat style="background: transparent">
                        <v-toolbar card flat class="header-toolbar" style="background: transparent">

                            <v-toolbar-items>
                                <v-btn icon flat class="pa-0 ma-0 hidden-xs-only" href="https://www.pulib.sk"><v-avatar tile><img src='/assets/logo.png'></v-avatar></v-btn>
                            </v-toolbar-items>



                            <v-toolbar-title class="headline">
                                <!--<a href="https://www.pulib.sk" class="header-logo"></a>-->

                                <!--<span><img src='/assets/logo.png' style="margin-top: 4px; vertical-align: middle; max-width:100%; max-height:100%; border:1px solid red;"></span>-->

                                <span class="font-weight-light">E-KNIHY</span>
                                <!--<v-chip label color="red darken-2" small text-color="white">BETA</v-chip>-->
                            </v-toolbar-title>


                            <v-spacer></v-spacer>

                            <v-scale-transition>
                                <v-text-field v-if="searchBox" v-model="searchFull" class="mx-0" flat solo hide-details autofocus label="Vyhľadávanie..."></v-text-field>
                            </v-scale-transition>

                            <v-tooltip bottom>
                                <v-btn icon slot="activator" @click="toggleSearch"><v-icon>{{searchBox ? 'mdi-close':'mdi-magnify'}}</v-icon></v-btn><span>{{searchBox ? 'Zrušiť vyhľadávanie':'Vyhľadávanie'}}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <v-btn icon slot="activator" @click="toggleViewMode"><v-icon>{{viewMode=='grid' ? 'mdi-view-headline':'mdi-view-module'}}</v-icon></v-btn><span>Zmeniť zobrazenie</span>
                            </v-tooltip>

                            <v-menu v-if="userToken" transition="slide-y-transition" min-width="300" offset-y left>
                                <v-btn flat icon class="text-lowercase" slot="activator"><v-icon>mdi-account-box-outline</v-icon></v-btn>
                                <v-card>
                                    <v-list>
                                    <v-list-tile avatar>
                                        <v-list-tile-avatar><img src="/assets/logo.png" alt="John"></v-list-tile-avatar>
                                        <v-list-tile-content>
                                            <v-list-tile-title>Čitateľ</v-list-tile-title>
                                            <v-list-tile-sub-title>Univerzitná knižnica PU</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    </v-list>
                                    <v-card-actions class="grey lighten-4">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" flat @click="logoutUser">Odhlásiť</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>

                        </v-toolbar>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-toolbar>

        <v-content>
            <catalog-element v-on:open="onDocumentOpen" />
        </v-content>


        <v-dialog persistent v-model="introDialog" max-width="600">
            <v-card>
                <v-card-title><v-icon class="red--text" large left>mdi-alert-outline</v-icon><span class="red--text title">Upozornenie</span></v-card-title>
                <v-card-text class="px-5 mb-3 text-xs-justify">
                    © Všetky práva vyhradené. <strong>Tieto elektronické dokumenty sú určené pre čitateľov Univerzitnej knižnice PU, výhradne na čítanie!</strong> V zmysle Autorského zákona č. 185/2015 Z. z sa žiadna časť z nich sa nesmie tlačiť, kopírovať, ukladať alebo inak rozširovať. Nerešpektovanie autorských práv môže mať trestno-právne dôsledky!
                </v-card-text>
                <v-card-actions class="grey lighten-3">
                    <v-spacer></v-spacer>
                    <v-btn href="https://www.pulib.sk" color="secondary" flat>Nesúhlasím</v-btn>
                    <v-btn depressed color="primary" @click="confirmIntro">Súhlasím</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loginDialog" max-width="600">
            <v-card>
                <v-card-title class="grey lighten-4"><v-icon class="blue-grey--text" large left>mdi-key-variant</v-icon><span class="blue-grey--text title">Prihlásenie</span></v-card-title>

                <v-card-text class="grey lighten-4" style="font-size: 120%;">
                    Na zobrazenie dokumentu je potrebné prihlásenie čítateľa. Použite prosím <strong>rovnaké prihlasovacie údaje</strong> ako do online katalógu univerzitnej knižnice.
                </v-card-text>
                <v-card-text>
                    <v-form @keyup.enter.native="loginUser">
                        <v-text-field name="login" solo flat v-model="login" :disabled="loginProgress" hide-details autofocus prepend-icon="mdi-account-outline" label="ID čitateľa alebo číslo čipu z karty" type="text"></v-text-field>
                        <v-text-field name="password" solo flat v-model="password" :disabled="loginProgress" hide-details prepend-icon="mdi-lock-open-outline" label="Heslo do online katalógu" type="password"></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-text v-if="loginError" class="grey lighten-4 ma-0 pa-0">
                    <v-alert :value="true" type="error">{{loginError}}</v-alert>
                </v-card-text>

                <v-card-actions class="grey lighten-4">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" flat @click="loginDialog = false" :disabled="loginProgress">Zrušiť</v-btn>
                    <v-btn color="primary" depressed @click="loginUser" :loading="loginProgress" :disabled="loginProgress">Prihlásiť</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-footer height="auto">
            <v-layout justify-center row wrap>
            <v-flex grey lighten-2 py-3 text-xs-center xs12>
                &copy; 2023 — Univerzitná knižnica Prešovskej univerzity v Prešove
            </v-flex>
            </v-layout>
        </v-footer>
    </v-app>

</template>

<style>

.application
{
    /* background-image: url('~/assets/background.jpg') !important; */
    background-repeat: no-repeat !important;
    background-color: #fff !important;
}

.header-logo
{
    /* background-image: url('~/assets/logo.png'); */
    height: 56px;
    width: 56px;
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
}

.header-toolbar .v-toolbar__content
{
    padding: 0 !important;
}

</style>
