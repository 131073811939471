<script>

import { mapState } from 'vuex';

export default
{
    data()
    {
        return {
            filteredBooks: [],
            tableHeaders: [
                { text: '', align: 'left', sortable: false, value: 'poster'},
                { text: 'Názov', align: 'left', sortable: true, value: 'name'},
                { text: 'Autor', align: 'left', sortable: true, value: 'author'},
                { text: 'Rok vydania', align: 'right', sortable: true, value: 'year'}
            ],
        };
    },
    computed: mapState(
    {
        books: state => state.books,
        fullSearch: state => state.fullSearch,
        viewMode: state => state.viewMode
    }),
    watch:
    {
        fullSearch: async function(value)
        {
            const scope = this;
            scope.filteredBooks = await scope.$store.dispatch('getBooks', {search: value});
        }
    },
    async mounted()
    {
        const scope = this;
        scope.filteredBooks = await scope.$store.dispatch('getBooks', {search: ''});
    },
    methods:
    {
        openBook(code)
        {
            const scope = this;
            //const url = `//cdn.pulib.sk/document/${code}.pdf`;
            //scope.$router.push({name: 'document', params: { codetype: 'isbn', code: code}});
            scope.$emit('open', code);
        },
        onSort(items, col, isDescending)
        {
            items.sort(function(a, b)
            {
                return !isDescending ? String(a[col]).localeCompare(String(b[col])) : String(b[col]).localeCompare(String(a[col]));
            });
            return items;
        }
    }
};
</script>


<template>

<v-layout>
    <v-flex xs12 sm10 offset-sm1>

        <!-- BOOK GRID -->
        <v-card v-if="viewMode=='grid'" class="elevation-0" style="background: transparent">
            <v-container grid-list-xl fluid>
                <v-layout row wrap>
                    <v-flex v-for="book in filteredBooks" :key="book.code" xs4 sm3 md2 d-flex>
                    <v-hover>
                        <v-card @click="openBook(book.code)" flat tile class="d-flex" slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 5}`">
                            <v-img :src="`//cdn.pulib.sk/document/${book.code}.jpg`" class="grey lighten-2">
                                <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-layout>
                                <v-slide-y-reverse-transition>
                                    <v-layout v-if="hover" fill-height align-end justify-center ma-0 class="book-preview">
                                        <v-flex xs12 class="pa-0">
                                            <v-card>
                                                <!--<v-img :src="`assets/thumbs/${book.code}.jpg`" aspect-ratio="2.75" ></v-img>-->
                                                <v-card-title primary-title>
                                                <div style="margin: 0 auto">
                                                    <h3 class="body-2 mb-2">{{book.name}}</h3>
                                                    <div class="caption">{{book.author}}</div>
                                                    <div class="caption">{{book.year}}</div>
                                                </div>
                                                </v-card-title>
                                                <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn flat icon color="primary">
                                                    <v-icon>mdi-book-open-page-variant</v-icon>
                                                </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-flex>
                                    </v-layout>
                                </v-slide-y-reverse-transition>
                            </v-img>
                        </v-card>
                    </v-hover>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>

        <!-- BOOK LIST -->
        <v-card v-else flat style="background: transparent">
            <v-data-table :items="filteredBooks" class="book-list mt-4 mb-4 elevation-0" :headers="tableHeaders" :custom-sort="onSort" sort-icon="mdi-menu-down" no-data-text="" hide-actions must-sort>
                <template slot="items" slot-scope="props">
                    <tr @click="openBook(props.item.code)">
                        <td><v-img :src="`//cdn.pulib.sk/document/${props.item.code}.jpg`" width="30" height="40"></v-img></td>
                        <td>{{ props.item.name }}</td>
                        <td>{{ props.item.author }}</td>
                        <td class="text-xs-right">{{ props.item.year }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-alert :value="filteredBooks.length==0" color="info" icon="mdi-information-outline" outline>
        Ľutujeme, pre vaše vyhľadávanie sa nenašiel žiadny výsledok...
        </v-alert>

    </v-flex>
</v-layout>

</template>

<style>

.book-list table.v-table
{
    background: transparent !important;
}

.book-list table.v-table tr
{
    cursor: pointer;
}

.book-preview
{
    background: #00000080;
    color: #fff;
    text-align: center;
    font-size: 14px !important;
}

</style>
